<template>
  <div>
    <div class="bg-danger">
      <div class="container">
        <div class="p-3 position-relative d-block">
          <button
            v-if="!isShowAlert"
            type="button"
            class="btn text-white position-absolute floating-btn d-flex"
            @click="handleBack"
          >
            <!-- <img class="icon-svg mr-1" src="../../../assets/arrow-left.svg" /> -->
            <i class="ri-arrow-left-line mr-2 align-middle"></i>
            <p class="m-0 align-middle">Back to Search</p>
          </button>
          <div class="text-center">
            <h4 class="text-white mb-0" v-if="!isShowAlert">New to SelCare?</h4>
            <h4 class="text-white mb-0" v-else>Start Your New Registration</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="container custom-container" v-if="!isShowAlert">
      <div
        class="
          position-relative
          d-flex
          justify-content-center
          align-items-center
          p-3
        "
      >
        <div class="card custom-card">
          <div class="card-header">
            <h5 class="mb-0">Your Personal Info</h5>
          </div>
          <div class="card-body border-bottom">
            <div class="form-group">
              <label>Full Name (Based on IC)</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label>Phone Number</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label>Username</label>
              <input v-model="username" type="email" class="form-control" />
            </div>
            <div class="form-group">
              <label>Email</label>
              <input v-model="email" type="email" class="form-control" />
            </div>
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label>Password</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Retype Password</label>
                <input type="password" class="form-control" />
              </div>
            </div>
          </div>
          <div class="card-body border-bottom">
            <div>
              <div class="form-group form-check mb-1">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  v-model="isAgree"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >I Agree with terms & conditions</label
                >
              </div>
              <!-- <div class="form-group form-check mb-1">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >Agree with PDPA</label
                >
              </div>
              <div class="form-group form-check mb-1">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >Not to record</label
                >
              </div> -->
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <button type="button" class="btn">Cancel</button>
              <button
                v-if="!authProcessing"
                type="button"
                class="btn btn-primary button-primary-color-bg"
                @click="submitRegistration"
                :disabled="!isAgree"
              >
                Next
              </button>
              <button
                v-else
                type="button"
                class="btn btn-primary button-primary-color-bg"
                disabled
              >
                <span class="spinner-border spinner-border-sm"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="isShowAlert">
      <div
        class="
          position-relative
          d-flex
          justify-content-center
          align-items-center
          p-3
          fixed-hight
        "
      >
        <div class="card custom-card-sm">
          <div class="card-body">
            <div
              class="
                img-container
                mt-2
                mb-4
                d-flex
                justify-content-center
                align-items-center
                w-100
              "
            >
              <img src="../../../assets/Illustration.svg" />
            </div>
            <h5 class="text-center">Account Created</h5>
            <p class="mb-4 text-center">
              Consectetur eros scelerisque eget in enim, risus, enim sit
              volutpat fringilla rhoncus mi tellus
            </p>
            <div class="text-center mb-3">
              <button
                type="button"
                class="btn btn-primary button-primary-color-bg"
                @click="$router.push({ path: '/e-prescription/' })"
              >
                Start Consultation
              </button>
            </div>
            <div class="text-center mb-2">
              <button
                type="button"
                class="btn"
                @click="$router.push({ path: '/' })"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RegisterForm",
  data() {
    return {
      username: null,
      email: null,
      password: null,
      isAgree: false,
      isShowAlert: false,
    };
  },
  computed: {
    ...mapGetters(["authProcessing", "authError"]),
  },
  methods: {
    ...mapActions(["signup"]),
    async submitRegistration() {
      let redirectto = "noredirect";

      await this.signup({
        username: this.username,
        email: this.email,
        password: this.password,
        redirectto: redirectto,
      });

      this.isShowAlert = true;
    },
    handleBack() {
      this.$router.push({
        path: "/e-prescription/checking",
      });
    },
  },
};
</script>

<style scoped>
.fixed-hight {
  height: calc(100vh - 200px);
}

.custom-container {
  height: calc(100vh - 142px);
  /* overflow: scroll; */
}

.img-container {
  height: 150px;
  width: 100px;
}

.img-container img {
  height: 100%;
  width: auto;
}

.custom-container::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.floating-btn {
  top: 12px;
}

.custom-card {
  width: 500px;
}

.custom-card-sm {
  min-width: 300px;
  max-width: 350px;
}

.button-primary-color-bg {
  background-color: #f97066 !important;
  border-color: #f97066 !important;
}

@media (max-width: 500px) {
  .floating-btn {
    top: 0;
    width: 100%;
    margin-bottom: 20px;
    position: relative !important;
  }
  .custom-card-sm {
    min-width: 300px;
    max-width: 350px;
  }
}
</style>